var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.results)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results.results,"options":_vm.options,"server-items-length":_vm.results.count,"loading":_vm.isLoading,"footer-props":{itemsPerPageOptions: [5, 25, 50, 100]}},on:{"update:options":[function($event){_vm.options=$event},function($event){_vm.options = _vm.options}]},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: (_vm.routerNamespace + ".detail"), params: {id: item.id}}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.client.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: "communities.detail", params: {id: item.client.id}}}},[_vm._v(" "+_vm._s(item.client.name)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"monospace",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name.slice(0, 8))+" ")])]}},{key:"item.ip.country_code",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"monospace",attrs:{"title":item.ip.country_name}},[_vm._v(" "+_vm._s(item.ip.country_code)+" ")])]}},{key:"item.last_update",fn:function(ref){
var item = ref.item;
return [_c('time',{attrs:{"title":item.last_update}},[_vm._v(" "+_vm._s(_vm.formatDate(item.last_update))+" ")])]}},{key:"top",fn:function(){return [_c('data-table-top',{ref:"top",attrs:{"form-title":_vm.formTitle,"item":_vm.editedItem,"entity-name":_vm.entityName,"entity-name-plural":_vm.entityNamePlural,"can-create":_vm.canCreate,"can-edit":_vm.canEdit,"query":_vm.filters.q},on:{"update:query":function($event){return _vm.$set(_vm.filters, "q", $event)},"save":function($event){return _vm.save($event)},"close":function($event){return _vm.close()}}})]},proxy:true}],null,false,254282407)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }