<template>
  <v-data-table
    :headers="headers"
    :items="results.results"
    :options.sync="options"
    :server-items-length="results.count"
    v-if="results"
    :loading="isLoading"
    :footer-props="{itemsPerPageOptions: [5, 25, 50, 100]}"
    @update:options="options = options"
  >
    <template v-slot:item.id="{ item }">
      <router-link :to="{name: `${routerNamespace}.detail`, params: {id: item.id}}">
        {{ item.id }}
      </router-link>
    </template>
    <template v-slot:item.client.name="{ item }">
      <router-link :to="{name: `communities.detail`, params: {id: item.client.id}}">
        {{ item.client.name }}
      </router-link>
    </template>
    <template v-slot:item.name="{ item }">
      <span :title="item.name" class="monospace">
        {{ item.name.slice(0, 8) }}
        </span>
    </template>
    <template v-slot:item.ip.country_code="{ item }">
      <span :title="item.ip.country_name" class="monospace">
        {{ item.ip.country_code }}
      </span>
    </template>
    <template v-slot:item.last_update="{ item }">
      <time :title="item.last_update">
        {{ formatDate(item.last_update) }}
      </time>
    </template>
    <template v-slot:top>
      <data-table-top
        :form-title="formTitle"
        :item="editedItem"
        :entity-name="entityName"
        :entity-name-plural="entityNamePlural"
        :can-create="canCreate"
        :can-edit="canEdit"
        :query.sync="filters.q"
        @save="save($event)"
        @close="close()"
        ref="top"
      >
       
      </data-table-top>
    </template>
  </v-data-table>
</template>

<script>

import DataTable from "./DataTable"
import DataTableTop from "./DataTableTop"
import {formatDate} from '@/time'

export default {
  mixins: [DataTable],
  components: {
    DataTableTop,
  },
  data () {
    return {
      transformations: []
    }
  },
  methods: {
    formatDate
  }
}
</script>